import { ApiResponse } from "@/types";

import { fetchWrapper } from "../hooks/fetch-client";

const FORM_ID = "3671730";

interface SubscribeProps {
	email: string;
	form_id?: string;
	placement?: string;
	form_name?: string;
}

interface SubscribeResponse {
	redirect: string;
	success: boolean;
}

async function subscribe({
	email,
	form_id = FORM_ID,
	placement,
	form_name,
}: SubscribeProps): Promise<ApiResponse<SubscribeResponse>> {
	return await fetchWrapper("/v1/forms/newsletter/subscribe", {
		method: "POST",
		body: JSON.stringify({
			email,
			form_id,
			url: window.location.origin + window.location.pathname,
			placement,
			form_name,
		}),
	});
}

export default subscribe;
