"use client";

import { ArrowRight } from "lucide-react";
import * as React from "react";

interface ContentLeftProps extends React.HTMLAttributes<HTMLDivElement> {
	disableCollapse?: boolean;
}

const ContentLeft = ({ disableCollapse = false, children }: ContentLeftProps) => {
	const [expanded, setExpanded] = React.useState(true);

	React.useEffect(() => {
		const handleResize = () => {
			if (disableCollapse) return;

			const isMobile = window.innerWidth <= 1440;
			setExpanded(!isMobile);
		};

		handleResize();

		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	return (
		<>
			<input
				type="checkbox"
				id="left-toggle"
				className="peer/left hidden"
				disabled={disableCollapse}
				checked={expanded}
				onChange={(e) => {
					window.mixpanel.track("Toggle left sidebar", {
						state: e.target.checked,
					});

					setExpanded((prev) => !prev);
				}}
			/>
			<nav className="group sticky top-20 hidden size-full max-w-15 space-y-5 whitespace-nowrap px-3 py-9.5 text-gray-700 shadow-left-side-inner transition-[max-width] duration-200 ease-in-out peer-checked/left:max-w-64 peer-checked/left:whitespace-normal lg:block 3xl:peer-checked/left:max-w-xs">
				{!disableCollapse && (
					<label
						htmlFor="left-toggle"
						className="ml-2 inline-block size-4 cursor-pointer rounded border-1.5 border-current text-gray-600"
					>
						<ArrowRight
							size={13}
							className="transition-transform duration-200 ease-in [input:checked+nav_&]:rotate-180"
						/>
					</label>
				)}
				<div className="space-y-3 overflow-hidden">{children}</div>
			</nav>
		</>
	);
};

export default ContentLeft;
