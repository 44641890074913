"use client";

import subscribe from "@lib/utils/subscribe-to-newsletter";
import Link from "next/link";
import { useState } from "react";

import { StayUpToDateIcon } from "@/components/icons/stay-up-to-date";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { redirectWithEmail } from "@/lib/helpers/redirect-with-email";
import { cn } from "@/lib/utils";
//import formActions from '../../store/actions/formActions'

interface StayUptoDateProps {
	className?: string;
}

const StayUptoDate = ({ className }: StayUptoDateProps) => {
	const [email, setEmail] = useState<string>("");
	const [error, setError] = useState<any>(null);
	const [status, setStatus] = useState<string>("");

	if (status === "success") {
		return (
			<p className={cn("mt-6", className)}>
				Thank you for becoming an Examine Insider.
				<br />
				<Link href={`/insiders/welcome/?email=${email}`} className="!underline hover:opacity-60">
					See what you get with your free account!
				</Link>
			</p>
		);
	}

	async function submit(event: React.FormEvent) {
		event.preventDefault();
		setStatus("loading");
		setError(null);

		try {
			// see https://app.convertkit.com/forms/874599/subscriptions
			//await formActions.newsletterSubscribe({ email, form_id: 3671730 })
			const res = await subscribe({
				email: email,
				placement: "footer",
				form_name: "footer",
			});

			if (res.errors) {
				throw new Error(res.message);
			}

			window.dataLayer = window.dataLayer || [];
			window.dataLayer?.push({
				event: "sign_up",
				method: "bento",
				user_email: email,
			});

			await redirectWithEmail("/insiders/welcome/", email);
			setStatus("success");
		} catch (err: any) {
			setError(err.message);
			setStatus("");
		}
	}

	return (
		<form className={cn("space-y-2.5", className)} onSubmit={submit}>
			<div className="relative mt-6 h-10 overflow-hidden rounded bg-white/20">
				<Input
					type="email"
					value={email}
					required
					onChange={(e) => setEmail(e.target.value)}
					className="size-full border-none bg-transparent px-3 text-sm placeholder:text-gray-300"
					placeholder="Your email address"
				/>

				<Button
					disabled={email.length === 0 || status === "loading"}
					type="submit"
					className="absolute right-0 top-1/2 -translate-y-1/2 p-4"
					Icon={<StayUpToDateIcon />}
					aria-label="Submit email to stay up to date"
				/>
			</div>
			{error !== null && <p className="text-red-500">{error}</p>}
		</form>
	);
};

export default StayUptoDate;
